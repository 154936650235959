.wrapper {
  height: 100%;
}

.footer {
  position: fixed;
  bottom: 0;
  background-color: #737575;
  width: 100%;
  height: 35px;
  padding: 10px;
  font-family: "Source Sans Pro", "Source Sans Pro Backup", sans-serif;
  letter-spacing: 0.4px;
  color: #fff;
}

.footer .copyrightLinks {
  display: inline-block;
  vertical-align: middle;
}

.footer ul {
  float: none;
  margin: 0px;
  padding: 0px;
}

.footer li {
  display: inline-block;
  margin: 0px 0.25em;
  font-size: 10px;
}

.footer a {
  color: #ffffff;
}

.copyrightText {
  font-size: 10px;
  letter-spacing: 0.4px;
  line-height: 12px;
}

.spLogo {
  float: right;
  padding-right: 20px;
}
