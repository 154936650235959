.login {
  position: fixed;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo {
  padding: 0 60px 0 0;
  margin: 85px 0;
  width: 600px;
}

.auth {
  color: #000;
  margin-bottom: 15px;
}

.authHeader {
  font-size: 38px;
}

.loginForm {
  border-left: 1px dashed #53575c;
  padding: 40px 60px;
}

.formGroup {
  position: relative;
}
.formControl {
  border-radius: 2px;
  width: 300px;
  height: 25px;
  margin-bottom: 20px;
  padding: 5px;
}
.btnSubmit {
  background-color: #c2c4c3;
  cursor: pointer;
  height: 34px;
  width: 100%;
  display: block;
  font-size: 14px;
  border: none;
  padding: 0;
  color: #000;
}

.btnSubmit:hover {
  background-color: #737575;
}

.loginFooter {
  margin-top: 15px;
  font-size: 14px;
}

.alert {
  padding: 15px;
  border: 1px solid transparent;
  border-radius: 4px;
  margin-bottom: 20px;
  background-color: #f2dede;
  border-color: #ebccd1;
  color: #a94442;
}
